<template>
  <div id="app">
<MainPage></MainPage>
  </div>
</template>
<script>

import MainPage from "@/views/mainPage.vue";

export default {
  components: {MainPage},
  data () {
    return {
    };
  }

};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>
