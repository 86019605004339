<template>
  <div class="page">
    <img src="../assets/banner.jpg" alt="" class="banner" />
    <div class="titleBox">
      <div class="title">联系我们</div>
    </div>
    <div class="main">
      <div class="introduceItem">
        <div class="title">山东中维世纪科技股份有限公司</div>
        <div class="phone">办公地址：山东省济南市高新区新泺大街1166号奥盛大厦3号楼12层</div>
        <div class="address">
         公司总机：0086-531-68621533
        </div>
        <div class="address">
         邮编：250101
        </div>
      </div>
      <div class="introduceItem">
        <div class="mailbox">客服电话：400-608-9888 (紧急联系电话：0531-88192915)</div>
        <div class="phone">商务电话：0086-531-68621533</div>
        <div class="address">商务传真：0086-531-68621537</div>
        <div class="phone">
         商务Email：market@jovision.com
        </div>
      </div>
<!--      <img src="../assets/map.png" alt="" class="map" />
      <div class="arrive">
        <div class="hint">到达方式：</div>
        <div class="hint">
          乘坐地铁：
          10号线岗头站C2出口出站，左转进入环城路直行300米，右转直行400米到达天安云谷3栋A座
        </div>
        <div class="hint">
          乘坐公交：
          抵达“坂田交警中队”站，沿环城路直行200米，右转直行400米到达天安云谷3栋A座；抵达“岗头发展大厦”站，沿坂田岗头路直行300米，右转进入坂云路，直行200米到达3栋A座
        </div>
      </div>-->
    </div>
    <div class="record">
     Copyright © 2011-2023 山东中维世纪科技股份有限公司 版权所有
      <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备09051556号-29</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "mainPage"
};
</script>

<style scoped lang="scss">
.page{
  height: 100vh;
}
.banner {
  width: 100%;
  height: auto;
}
.titleBox {
  width: 70%;
  margin: 0 auto;
  text-align: left;
  border-bottom: 1px solid #ddd;
  margin-top: 40px;
  .title {
    display: inline-block;
    padding: 3px 2px;
    font-size: 16px;
    color: #1f1f1f;
    border-bottom: 2px solid #bd1c22;
  }
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 40px;
  .introduceItem {
    padding: 12px 0;
    color: #000;
    line-height: 1.6;
    border-bottom: 1px dashed #ddd;
  }
  .title {
    font-size: 22px;
    color: #b70000;
    padding-bottom: 10px;
  }
  .map {
    margin: 30px auto;
  }
  .arrive {
    .hint {
      margin: 20px 0;
      &:nth-of-type(1){
        margin-top: 0;
      }
    }
  }
}
.record {
  background-color: #F4F4F4;
    color:#666;
  height: 50px;
  line-height: 50px;
  text-align: center;
position: absolute;
  bottom:0;
  width: 100%;
  a{
    color:#666
  }
}
</style>
