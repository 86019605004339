import Vue from "vue";
import App from "./App.vue";
import "./assets/reset.css";


// 引入 moment
import moment from "moment";

Vue.config.productionTip = false;

// 绑定到vue
Vue.prototype.$moment = moment;

new Vue({
  render: (h) => h(App)
}).$mount("#app");
